<script setup lang="ts">
import { useHydraNodeStore } from '~/stores/hydra-node';
import TableSkeleton from '~/components/shared/templates/Skeletons/TableSkeleton.vue';
import TableWithSidebarErrorPlaceholder from '~/components/pro/templates/TableWithSidebarErrorPlaceholder.vue';
const hydraNodeStore = useHydraNodeStore();

const handleResetNode = async (err:unknown) => {
  await hydraNodeStore.resetNode();
  // @ts-ignore argument unknown
  await clearError(err);
};
</script>

<template>
  <NuxtLayout name="core" sidebar>
    <template #title>
      <slot name="title">
        {{ $attrs.title }}
      </slot>
    </template>

    <slot name="default" />

    <template #skeleton>
      <TableSkeleton />
    </template>
    <template #error="{message}">
      <NuxtErrorBoundary>
        <TableWithSidebarErrorPlaceholder :message="message">
          <UButton @click="handleResetNode">
            {{ $t('errors.node.remove-error-btn') }}
          </UButton>
        </TableWithSidebarErrorPlaceholder>
      </NuxtErrorBoundary>
    </template>
  </NuxtLayout>
</template>
